import React, { useRef, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './quill-wrapper-styles.css'

function QuillEditor({ value, onChange, placeholder, addClasses }) {
  const quillRef = useRef(null)
  const containerRef = useRef(null)

  const modules = {
    toolbar: [
      // [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline'],
      ['link'],
      [{ align: [] }],
      ['clean'],
    ],
  }

  const formats = ['size', 'bold', 'italic', 'underline', 'link', 'align']

  useEffect(() => {
    if (!quillRef.current) return

    const quill = quillRef.current.getEditor()
    const container = containerRef.current

    const updateHeight = () => {
      if (!quill || !container) return
      const contentHeight = quill.root.scrollHeight
      const newHeight = Math.min(Math.max(contentHeight + 42, 120), 300)
      container.style.height = `${newHeight}px`
    }

    quill.on('text-change', updateHeight)
    updateHeight() // Initial call

    return () => {
      quill.off('text-change', updateHeight)
    }
  }, [])

  const inputClasses = [' quill-container ', ...(Array.isArray(addClasses) ? addClasses : [])].filter(Boolean).join(' ')

  return (
    <div ref={containerRef} className={inputClasses}>
      <ReactQuill
        ref={quillRef}
        theme='snow'
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    </div>
  )
}

export default QuillEditor
