import TokenIcons from '../../TokenData/TokenIcons'
import React, { useEffect, useState } from 'react'
import api from 'api'
import '../../../secondaryLayout/scss/main.sass'
import QuillEditor from 'pages/common/components/TextEditor/QuillEditor'
import TokenInput from 'pages/tokens/TokenData/TokenInput'

const FillingDataNewsActicle = ({ t, onInputChange, newsArticle }) => {
  const [isLoadingImage, setIsLoadingImage] = useState(false)

  const handleSelectedImages = async (event) => {
    try {
      let file = event.target.files[0]
      if (!file) return
      const reader = new FileReader()
      reader.onloadend = () => {
        onInputChange('imageUrl', reader.result)
        onInputChange('selectedImageFile', file)
      }
      reader.readAsDataURL(file)
    } catch (error) {
      console.error('Error processing image:', error)
      setIsErrorLogoImageSize(true)
    }
  }
  const fetchCurrencyListingImage = (id) => {
    setIsLoadingImage(true)
    api
      .fetchCurrencyListingImage(id, { responseType: 'blob' })
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob)
        onInputChange('imageUrl', objectURL)
      })
      .catch((error) => {
        console.error('Error fetchCurrencyListingImage', error)
      })
      .finally(() => setIsLoadingImage(false))
  }

  const renderImages = () => {
    if (isLoadingImage) return <TokenIcons name={'blurredTokenIco'} />

    if (newsArticle.imageUrl) {
      return <img src={newsArticle.imageUrl} alt='image' />
    }

    if (!newsArticle.imageId) {
      return (
        <React.Fragment>
          <TokenIcons name={'editIco'} />
          <span className='avatar__text'>
            {t('TokenPasteYour')}
            <br />
            {t('TokenUpdateImageHere')}
          </span>
        </React.Fragment>
      )
    }
  }

  useEffect(() => {
    if (!newsArticle || !newsArticle.imageId || newsArticle.selectedImageFile || newsArticle.imageUrl) return
    fetchCurrencyListingImage(newsArticle.imageId)
  }, [newsArticle])

  return (
    <React.Fragment>
      <div id='tokens__images' className='tokens__group group'>
        <div className='group__main'>
          <div className='group__header'>
            <div className='token__header'>
              <div className='token__avatar avatar'>
                <input
                  className='avatar__input'
                  type='file'
                  onChange={(event) => handleSelectedImages(event)}
                  accept='image/*'
                />
                {renderImages()}
              </div>
              <div className='token__title'>
                <span className='token__text h3'>{t('TokenUploadUpdateImage')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='tokens__about' className='tokens__group group'>
        <div className='group__header'>
          <span className='group__title'>{t('TokenUpdateInfo')}:</span>
        </div>
        <div className='group__main'>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input']}
                  value={newsArticle.title || ''}
                  inputCallback={(value) => {
                    onInputChange('title', value)
                  }}
                  placeholder={'Title'}
                  onBlur={(e) => {
                    onInputChange('title', e.target.value.trim())
                  }}
                />
                <TokenIcons name={'editIco'} />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterTheHeaderOfYourProject')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <label className='form__field'>
                <TokenInput
                  addClasses={['form__input']}
                  value={newsArticle.subtitle || ''}
                  inputCallback={(value) => {
                    onInputChange('subtitle', value)
                  }}
                  placeholder={'Subtitle'}
                  onBlur={(e) => {
                    onInputChange('subtitle', e.target.value.trim())
                  }}
                />
                <TokenIcons name={'editIco'} />
              </label>
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterTheSubtitleOfYourProject')}</span>
            </div>
          </div>
          <div className='form__item'>
            <div className='form__main'>
              <QuillEditor
                value={newsArticle.content || ''}
                placeholder={'Description'}
                onChange={(value) => onInputChange('content', value.trim())}
              />
            </div>
            <div className='form__footer'>
              <span className='form__tooltip'>{t('TokenEnterTheDescritionOfYourProject')}</span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FillingDataNewsActicle
