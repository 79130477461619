import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import Header from '../../common/components/Header/Header.jsx'
import Footer from '../../common/components/Footer/Footer.jsx'
import '../../secondaryLayout/scss/main.sass'
import TokenIcons from '../TokenData/TokenIcons.js'
import TokenInput from '../TokenData/TokenInput.jsx'
import EditTokenPage from './Edit/EditTokenPage.jsx'
import api from 'api.js'
import { toast } from 'react-toastify'
import { useAuth } from 'auth/auth.js'
import { useHistory } from 'react-router-dom'

const MAX_TOKENS_PER_USER = 5
const MIN_TOTAL_SUPPLY = 100
const MAX_TOTAL_SUPPLY = 1000000000000
const DEFAULT_TOTAL_SUPPLY = 10000000
const MIN_TOKEN_DECIMAL_PLACES = 0
const MAX_TOKEN_DECIMAL_PLACES = 8

export default function CreateTokenPage() {
  let auth = useAuth()
  const history = useHistory()
  const { t } = useTranslation()

  const [createTokenData, setCreateTokenData] = useState({
    currencyCode: '',
    tokenName: '',
    totalTokensAmount: 10000000,
    tokenDecimalPlaces: 4,
    paymentAmount: '',
    userId: '',
    projectGoal: '',
    investmentCondition: '',
    investmentConditionHint: '',
    category: '',
    location: '',
    officialLink: '',
    whitePaperLink: '',
    logoImageId: '',
    headerImageId: '',
    title: '',
    subtitle: '',
    content: '',
    projectImageId: '',
    socialLink1: '',
    socialLink2: '',
    socialLink3: '',
    socialLink4: '',
  })

  const [isNextStep, setIsNextStep] = useState(false)
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true)
  const [isCurrencyCodeAvailable, setIsCurrencyCodeAvailable] = useState(true)
  const [allCurrencies, setAllCurrencies] = useState(null)
  const [allCurrencyListingCodes, setAllCurrencyListingCodes] = useState(null)
  const [userCurrencyListings, setUserCurrencyListings] = useState(null)
  const [isMaxTokensForUser, setIsMaxTokensForUser] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleInputChange = (name, value) => {
    setCreateTokenData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const handleConfirm = () => {
    if (!auth.isSignedIn) {
      goToRegistrationPage()
      return
    }
    if (!isMaxTokensForUser) {
      sendNotification()
      return
    }
    setIsNextStep(true)
  }

  const validateCurrencyCode = async (currencyCode) => {
    if (!currencyCode || currencyCode === '') return
    if (!allCurrencies || !allCurrencyListingCodes) return

    const isInPairsList = allCurrencies.some((code) => code === currencyCode)
    const isInListings = allCurrencyListingCodes.some((code) => code === currencyCode)

    if (!isInPairsList && !isInListings) {
      setIsCurrencyCodeAvailable(true)
    } else {
      setIsCurrencyCodeAvailable(false)
    }
  }
  const goToRegistrationPage = () => history.push(`/${i18n.language}/register`)

  const fetchUserCurrencyListings = async () => {
    api
      .fetchCurrencyListings()
      .then((data) => {
        setUserCurrencyListings(data)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(`Error fetchCurrencyListings`, error)
      })
  }
  const fetchAllCurrencies = async () => {
    api
      .fetchCurrencies(false)
      .then((data) => {
        setAllCurrencies(data)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(`Error fetchCurrencyPairs`, error)
      })
  }

  const fetchCurrencyListingCodes = async () => {
    api
      .fetchCurrencyListingCodes()
      .then((data) => {
        setAllCurrencyListingCodes(data)
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(`Error fetchCurrencyListingCodes`, error)
      })
  }

  const sendNotification = () => {
    toast.warning(t('TokenYouHaveReachedTheMaximum', { MAX_TOKENS_PER_USER: MAX_TOKENS_PER_USER }), {
      autoClose: 5000,
    })
  }

  const isTotalTokensAmountValid = (amount) => {
    const numAmount = Number(amount)
    if (isNaN(numAmount)) return false
    if (!Number.isInteger(numAmount)) return false
    return numAmount >= 100 && numAmount <= 1000000000000
  }
  const handleNextButtonDisabled = () => {
    const trimmedName = createTokenData.tokenName.trim()
    const words = trimmedName.split(/\s+/)
    const tokenNameIsValid = trimmedName.length !== 0 && words.length >= 1 && words.length <= 3
    const currencyCodeIsValid = /^[A-Z]{3,7}$/.test(createTokenData.currencyCode)

    if (!auth.isSignedIn) {
      setIsConfirmBtnDisabled(!tokenNameIsValid || !currencyCodeIsValid)
      return
    }
    const tokenDecimalPlacesIsValid = /^[0-8]{1}$/.test(createTokenData.tokenDecimalPlaces)
    const totalTokensAmountValid = isTotalTokensAmountValid(createTokenData.totalTokensAmount)

    const isDisabled =
      !allCurrencies ||
      !allCurrencyListingCodes ||
      !userCurrencyListings ||
      !tokenNameIsValid ||
      !currencyCodeIsValid ||
      !tokenDecimalPlacesIsValid ||
      !isCurrencyCodeAvailable ||
      !totalTokensAmountValid

    setIsConfirmBtnDisabled(isDisabled)
  }

  useEffect(() => {
    handleNextButtonDisabled()
  }, [createTokenData, isCurrencyCodeAvailable])

  useEffect(() => {
    handleNextButtonDisabled()
    if (!allCurrencies || !allCurrencyListingCodes || !userCurrencyListings) {
      if (auth.isSignedIn) setIsLoading(true)
      return
    }
    if (userCurrencyListings.length < MAX_TOKENS_PER_USER) setIsMaxTokensForUser(true)
    setIsLoading(false)
  }, [allCurrencies, allCurrencyListingCodes, userCurrencyListings])

  useEffect(() => {
    if (!auth.isSignedIn) {
      setIsConfirmBtnDisabled(true)
      return
    }
    fetchAllCurrencies()
    fetchCurrencyListingCodes()
    fetchUserCurrencyListings()
  }, [])

  return (
    <React.Fragment>
      {isNextStep && (
        <EditTokenPage
          createTokenData={createTokenData}
          onCreateTokenData={(tokenData) => {
            setCreateTokenData(tokenData)
            setIsNextStep(false)
          }}
        />
      )}
      {!isNextStep && (
        <div className='layout_Tokens'>
          <Header />
          <main className='layout__main'>
            <section className='layout__section section'>
              <div className='container'>
                <div className='section__header'>
                  <h2 className='section__title'>{t('TokenCreateYourToken')}</h2>
                </div>
                <div className='section__main'>
                  <div className='layout__token token'>
                    <div className='token__form form' action='#'>
                      <div className='token__group group'>
                        <div className='group__main'>
                          <div className='form__item'>
                            <div className='form__main'>
                              <label className='form__field'>
                                <TokenInput
                                  addClasses={['form__input', 'form__input_primary']}
                                  value={createTokenData.tokenName || ''}
                                  validationPattern='^ *([a-zA-Z]+ ?){1,3}$'
                                  inputCallback={(value) => {
                                    handleInputChange('tokenName', value)
                                  }}
                                  placeholder={t('TokenWriteYourTokenName')}
                                  onFocus={(e) => {
                                    handleInputChange('tokenName', e.target.value.trim())
                                  }}
                                />
                              </label>
                            </div>
                            <div className='form__footer'>
                              <span className='form__tooltip'>{t('TokenYourProjectUnabbreviatedName')}</span>
                            </div>
                          </div>
                          <div className='form__item'>
                            <div className='form__main'>
                              <label className='form__field'>
                                <TokenInput
                                  addClasses={['form__input', 'form__input_primary']}
                                  value={createTokenData.currencyCode || ''}
                                  validationPattern='^[a-zA-Z]{0,7}$'
                                  inputCallback={(value) => {
                                    handleInputChange('currencyCode', value.toUpperCase())
                                    setIsCurrencyCodeAvailable(true)
                                  }}
                                  placeholder={t('TokenWriteShortTokenSymbol')}
                                  onBlur={(e) => {
                                    validateCurrencyCode(e.target.value.trim())
                                  }}
                                  isValid={isCurrencyCodeAvailable}
                                />
                              </label>
                            </div>
                            <div className='form__footer'>
                              <span className='form__tooltip'>
                                {!isCurrencyCodeAvailable && (
                                  <span style={{ color: 'red' }}>{t('TokenThisNameAlreadyExists')}</span>
                                )}{' '}
                                {t('TokenCurrencySymbolAppearing')}
                              </span>
                            </div>
                          </div>
                          <div className='form__item'>
                            <div className='form__main'>
                              <label className='form__field'>
                                <TokenInput
                                  addClasses={['form__input', 'form__input_primary']}
                                  value={createTokenData.tokenDecimalPlaces || ''}
                                  validationPattern='^[0-8]{0,1}$'
                                  inputCallback={(value) => {
                                    handleInputChange('tokenDecimalPlaces', value)
                                  }}
                                  placeholder={t('TokenWriteDecimalPrecisionOfYourToken')}
                                  onBlur={(e) => {
                                    let numericValue = e.target.value.trim().replace(/[^\d\s]+|\s+/g, '')
                                    if (numericValue === '') handleInputChange('tokenDecimalPlaces', 4)
                                  }}
                                />
                              </label>
                            </div>
                            <div className='form__footer'>
                              <span className='form__tooltip'>{t('TokenTheDecimalPrecisionOfYourToken')}</span>
                            </div>
                          </div>
                          <div className='form__item'>
                            <div className='form__main'>
                              <label className='form__field'>
                                <TokenInput
                                  addClasses={['form__input', 'form__input_primary']}
                                  type='text'
                                  value={formatNumber(createTokenData.totalTokensAmount) || ''}
                                  // validationPattern='^[0-9]{1,20}$'
                                  inputCallback={(value) => {
                                    let numericValue = value.trim().replace(/[^\d\s]+|\s+/g, '')
                                    handleInputChange('totalTokensAmount', numericValue)
                                  }}
                                  onBlur={(e) => {
                                    let numericValue = e.target.value.trim().replace(/[^\d\s]+|\s+/g, '')
                                    if (numericValue === '')
                                      handleInputChange('totalTokensAmount', DEFAULT_TOTAL_SUPPLY)
                                    if (numericValue > MAX_TOTAL_SUPPLY)
                                      handleInputChange('totalTokensAmount', MAX_TOTAL_SUPPLY)
                                    if (numericValue < MIN_TOTAL_SUPPLY)
                                      handleInputChange('totalTokensAmount', MIN_TOTAL_SUPPLY)
                                  }}
                                />
                              </label>
                            </div>
                            <div className='form__footer'>
                              <span className='form__tooltip'>
                                {t('TokenAllowedQuantity')} {MIN_TOTAL_SUPPLY.toLocaleString()} {' - '}
                                {MAX_TOTAL_SUPPLY.toLocaleString()}
                                {'. '} {t('TokenNumberOfInitialTokensWill')}{' '}
                                {formatNumber(createTokenData.totalTokensAmount)}
                              </span>
                            </div>
                          </div>
                          <div className='form__item'>
                            <div className='form__main'>
                              <button className='btn' disabled={isConfirmBtnDisabled} onClick={handleConfirm}>
                                <span className='btn__text'>{isLoading ? t('TokenLoading') : t('Next')}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='token__group group'>
                        <div className='group__main'>
                          <div className='form__item'>
                            <div className='form__main'>
                              <label className='form__field'>
                                <textarea className='form__input form__input_textarea form__input_textareaSecondary form__input_primary' />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='group__footer'>
                          <div className='token__service service'>
                            <span className='service__title h3'>{t('Token247Service')}</span>
                            {/* <span className='service__text'>{t('TokenSampleTextAbout247Service')}</span> */}
                          </div>
                          <div className='token_support support'>
                            <a className='support__link'>
                              <TokenIcons name={'tooltipIcon'} />
                              <span className='support__text'>{t('TokenBlazarexSupport')}</span>
                              <TokenIcons name={'arrowrightIcon'} />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </div>
      )}
    </React.Fragment>
  )
}
