import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import Header from '../../../common/components/Header/Header.jsx'
import Footer from '../../../common/components/Footer/Footer.jsx'
import '../../../secondaryLayout/scss/main.sass'
import { useParams, useLocation } from 'react-router-dom'
import MainInfo from './MainInfo.jsx'
import Links from './Links.jsx'
import Social from './Social.jsx'
import AboutProject from './AboutProject.jsx'
import api from 'api.js'
import { useHistory } from 'react-router-dom'
import { ImageType } from 'pages/tokens/TokenData/TokenData.js'
import TokenImages from './TokenImages.jsx'
import { toast } from 'react-toastify'
import CreateInBlockchainStubModal from './CreateInBlockchainStubModal.jsx'

export default function EditTokenPage({ createTokenData = null, onCreateTokenData = null }) {
  const { t } = useTranslation()
  const { code } = useParams()
  const history = useHistory()

  const [tokenData, setTokenData] = useState(
    createTokenData ?? {
      currencyCode: '',
      tokenName: '',
      tokenDecimalPlaces: '',
      paymentAmount: '',
      userId: '',
      projectGoal: '',
      investmentCondition: '',
      investmentConditionHint: '',
      category: '',
      location: '',
      officialLink: '',
      whitePaperLink: '',
      logoImageId: '',
      headerImageId: '',
      title: '',
      subtitle: '',
      content: '',
      projectImageId: '',
      socialLink1: '',
      socialLink2: '',
      socialLink3: '',
      socialLink4: '',
    },
  )

  const [selectedImages, setSelectedImages] = useState([
    { name: ImageType.Header, url: null, file: null, oldImageId: null, isLoadingImage: code ? true : false },
    { name: ImageType.Logo, url: null, file: null, oldImageId: null, isLoadingImage: code ? true : false },
    { name: ImageType.Project, url: null, file: null, oldImageId: null, isLoadingImage: code ? true : false },
  ])

  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState(true)
  const [isLoadExistingImages, setIsLoadExistingImages] = useState(false)
  const [isChanged, setIsChanged] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [scroll, setScroll] = useState(false)
  const [scrollCheck, setScrollCheck] = useState(false)
  const [activeSection, setActiveSection] = useState('tokens__images')

  const sectionRefs = {
    tokens__images: useRef(null),
    tokens__info: useRef(null),
    tokens__links: useRef(null),
    tokens__socials: useRef(null),
    tokens__about: useRef(null),
  }

  const sectionTitles = {
    tokens__images: 'TokenTokenImages',
    tokens__info: 'TokenMainInformation',
    tokens__links: 'TokenLinks',
    tokens__socials: 'TokenSocialMedia',
    tokens__about: 'TokenAboutProject',
  }

  const handleInputChange = (name, value) => {
    setTokenData((prevData) => {
      const newValue = value ?? ''
      if (prevData[name] === newValue) {
        return prevData
      }
      setIsChanged(true)
      return {
        ...prevData,
        [name]: newValue,
      }
    })
  }

  const checkSelectedImage = () => {
    return selectedImages.filter((image) => image.name !== ImageType.Header).every((image) => image.file !== null)
  }

  const fieldsValidation = () => {
    const checkFields = () => {
      const requiredFields = [
        'investmentCondition',
        'category',
        'location',
        'title',
        'subtitle',
        'content',
        'socialLink1',
        'socialLink2',
        'socialLink3',
      ]

      const result = requiredFields.every((field) => tokenData[field] && tokenData[field].trim() !== '')
      return result
    }
    const checkLinks = () => {
      const officialLinkIsValid = /^https:\/\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]+$/.test(tokenData.officialLink)
      const whitePaperLinkIsValid = /^https:\/\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]+$/.test(tokenData.whitePaperLink)
      const result = officialLinkIsValid && whitePaperLinkIsValid
      return result
    }

    const isValid = isChanged

    setIsConfirmBtnDisabled(!isValid)
  }

  const createUploadImage = async (image) => {
    if (!image.file) return null

    try {
      return {
        Extension: image.file.name.split('.').pop().toLowerCase(),
        Data: await fileToByteArray(image.file),
        ...(image.oldImageId && { oldImageId: image.oldImageId }),
      }
    } catch (error) {
      console.error(`Error creating upload image for ${image.name}:`, error)
      return null
    }
  }

  const uploadImage = async (image) => {
    try {
      const uploadImage = await createUploadImage(image)
      if (!uploadImage) return null
      const imageId = await api.uploadCurrencyListingImage(uploadImage)
      return { name: image.name, imageId }
    } catch (error) {
      console.error(`Error processing image ${image.name}:`, error)
      return null
    }
  }

  const updateTokenDataWithImages = (prevData, validUploadResults) => {
    const newData = { ...prevData }
    validUploadResults.forEach((result) => {
      newData[`${result.name}ImageId`] = result.imageId
    })
    return newData
  }

  const handleApiRequest = async (tokenData) => {
    try {
      const apiFunction = code ? api.updateCurrencyListing : api.createCurrencyListing
      await apiFunction(tokenData)
    } catch (error) {
      toast.error(t('ErrorTryLater'))
      console.error(`Error ${apiFunction.name}:`, error)
      throw new Error(`${apiFunction.name}:{error}`)
    }
  }

  const fileToByteArray = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        resolve(Array.from(new Uint8Array(event.target.result)))
      }
      reader.onerror = (error) => reject(error)
      reader.readAsArrayBuffer(file)
    })
  }
  const handleClickConfirm = async () => {
    setIsLoading(true)
    try {
      const uploadResults = await Promise.all(selectedImages.map(uploadImage))
      const validUploadResults = uploadResults.filter((result) => result !== null)

      const updatedTokenData = await new Promise((resolve) => {
        setTokenData((prevData) => {
          const newData = updateTokenDataWithImages(prevData, validUploadResults)
          resolve(newData)
          return newData
        })
      })
      await handleApiRequest(updatedTokenData)
      toast.success(t('ActionWasSuccessful'))
      goToTokenPage()
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const handleClickBack = () => {
    if (createTokenData) onCreateTokenData(tokenData)
    else goToTokenPage()
  }
  const goToTokenPage = () => {
    history.push(`/${i18n.language}/tokens/view/${tokenData.currencyCode}`)
  }
  const handleScroll = () => {
    const tokensMain = document.getElementById('tokens__main')
    const tokensImages = document.getElementById('tokens__images')
    const tokensInfo = document.getElementById('tokens__info')
    const tokensLinks = document.getElementById('tokens__links')
    const tokensSocials = document.getElementById('tokens__socials')
    const tokensAbout = document.getElementById('tokens__about')
    const tokensSidebar = document.getElementById('tokens__sidebar')
    const layoutFooter = document.getElementById('layout__footer')

    if (tokensMain) setScroll(tokensMain.getBoundingClientRect().top < 0)
    if (tokensImages && tokensImages.getBoundingClientRect().top < 0) setActiveSection('tokens__images')
    if (tokensInfo && tokensInfo.getBoundingClientRect().top < 0) setActiveSection('tokens__info')
    if (tokensLinks && tokensLinks.getBoundingClientRect().top < 0) setActiveSection('tokens__links')
    if (tokensSocials && tokensSocials.getBoundingClientRect().top < 0) setActiveSection('tokens__socials')
    if (tokensAbout && tokensAbout.getBoundingClientRect().top < 0) setActiveSection('tokens__about')

    if (tokensSidebar && layoutFooter) {
      if (window.pageYOffset + tokensSidebar.offsetHeight + tokensSidebar.offsetTop > layoutFooter.offsetTop) {
        setScrollCheck(true)
      }
      if (layoutFooter.getBoundingClientRect().top > window.innerHeight) {
        setScrollCheck(false)
      }
    }
  }
  const scrollToSection = (sectionId) => {
    sectionRefs[sectionId].current.scrollIntoView({ behavior: 'smooth' })
    setActiveSection(sectionId)
  }
  const fetchData = async (ignore) => {
    if (code) {
      setIsLoading(true)
      try {
        const data = await api.fetchCurrencyListing(code)
        if (ignore) return
        setTokenData(data)

        setSelectedImages((prevImages) =>
          prevImages.map((image) => {
            switch (image.name) {
              case ImageType.Header:
                return { ...image, oldImageId: data.headerImageId, isLoadingImage: data.headerImageId ?? false }
              case ImageType.Logo:
                return { ...image, oldImageId: data.logoImageId, isLoadingImage: data.logoImageId ?? false }
              case ImageType.Project:
                return { ...image, oldImageId: data.projectImageId, isLoadingImage: data.projectImageId ?? false }
              default:
                return image
            }
          }),
        )

        setIsLoadExistingImages(true)
        setIsChanged(false)
      } catch (error) {
        console.error('Error fetching token data:', error)
      }
    } else if (!code && !tokenData) {
      if (ignore) return
      console.error('No token data available')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    let ignore = false
    if (!code) return
    fetchData(ignore)

    return () => {
      ignore = true
    }
  }, [code])

  useEffect(() => {
    if (!tokenData) return
    fieldsValidation()
  }, [tokenData, selectedImages])

  return (
    <React.Fragment>
      <div className='layout_Tokens'>
        <Header />
        <main className='layout__main'>
          <section className='layout__section section'>
            <div className='container'>
              <div className='section__header'>
                <h2 className='section__title'>
                  {t(createTokenData ? 'TokenCreateYourTokensPage' : 'TokenEditYourTokensPage')}
                </h2>
              </div>
              <div className='section__main'>
                <div className='layout__tokens tokens'>
                  <div className='tokens__form form' action='#'>
                    {/*  Sidebar */}
                    <aside
                      id='tokens__sidebar'
                      className={`tokens__sidebar sidebar ${scroll ? 'tokens__sidebar_fixed' : ''} ${
                        scrollCheck ? 'tokens__sidebar_bottom' : ''
                      }`}
                    >
                      <div className='sidebar__header'>
                        <span className='sidebar__title'>{t('TokenNavigation')}:</span>
                      </div>
                      <div className='sidebar__main'>
                        <nav className='tokens__nav nav'>
                          <ul className='nav__list'>
                            {Object.entries(sectionRefs).map(([id, ref]) => (
                              <li
                                key={id}
                                className={`nav__item ${activeSection === id ? 'nav__item_active' : ''}`}
                                onClick={() => scrollToSection(id)}
                              >
                                <span className='nav__link'>{t(sectionTitles[id])}</span>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                      <div className='sidebar__footer'>
                        <div className='form__item'>
                          <div className='form__main'>
                            <button className='btn btn_secondary' disabled={isLoading} onClick={handleClickBack}>
                              <span className='btn__text'>{t('Back')}</span>
                            </button>
                            <button
                              className='btn'
                              disabled={isConfirmBtnDisabled || isLoading}
                              onClick={handleClickConfirm}
                            >
                              <span className='btn__text'>{isLoading ? t('TokenLoading') : t('Confirm')}</span>
                            </button>
                            <button className='btn' onClick={() => setIsOpenModal(true)}>
                              <span className='btn__text'>{t('TokenCreateInBlockchain')}</span>
                            </button>
                          </div>
                          <div className='form__footer'>
                            {/* <span className='form__tooltip'>{t('TokenYourChangesHaveBeenSaved')}</span> */}
                          </div>
                        </div>
                      </div>
                    </aside>
                    {/*  Main */}
                    <main id='tokens__main' className='tokens__main'>
                      <div ref={sectionRefs.tokens__images}>
                        <TokenImages
                          isLoadExistingImages={isLoadExistingImages}
                          onImageChange={() => setIsChanged(true)}
                          selectedImages={selectedImages}
                          setSelectedImages={setSelectedImages}
                          tokenData={tokenData}
                        />
                      </div>
                      <div ref={sectionRefs.tokens__info}>
                        <MainInfo onInputChange={handleInputChange} tokenData={tokenData} />
                      </div>
                      <div ref={sectionRefs.tokens__links}>
                        <Links onInputChange={handleInputChange} tokenData={tokenData} />
                      </div>
                      <div ref={sectionRefs.tokens__socials}>
                        <Social onInputChange={handleInputChange} tokenData={tokenData} />
                      </div>
                      <div ref={sectionRefs.tokens__about}>
                        <AboutProject onInputChange={handleInputChange} tokenData={tokenData} />
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
      <CreateInBlockchainStubModal onClose={() => setIsOpenModal(false)} isOpened={isOpenModal} t={t} />
    </React.Fragment>
  )
}
