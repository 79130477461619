import React, { useEffect, useState } from 'react'
import style from '../style/TokenPage.module.scss'
import { cssClass } from 'helpers/helper'
import TokenIcons from '../TokenData/TokenIcons'
import { FormatedDate, LikesCount } from '../TokenData/TokenData'
import CommentsTab from '../Comments/CommentsTab'
import api from 'api'
import QuillHTMLOutput from 'pages/common/components/TextEditor/QuillHTMLOutput'

const NewsArticlesMoreDetail = ({
  t,
  index,
  selectedNews,
  onLikeUpdateForNews,
  newsArticleCommentsCount,
  isAuthor,
  isEditNews,
  onUpdateNewsCommentsCount,
}) => {
  const [selectedNewsImage, setselectedNewsImage] = useState(null)
  const [news, setNews] = useState(null)
  const [newsLikes, setNewsLikes] = useState(null)

  useEffect(() => {
    let ignore = false
    if (!news?.imageId) return
    api
      .fetchCurrencyListingImage(news?.imageId, { responseType: 'blob' })
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob)
        setselectedNewsImage(objectURL)
      })
      .catch((error) => {
        if (ignore) return
        console.error('Error fetchCurrencyListingImage', error)
      })
    return () => {
      ignore = true
    }
  }, [news?.imageId])

  useEffect(() => {
    if (!selectedNews || selectedNews?.newsVersions?.length === 0) return
    const lastNews = selectedNews.newsVersions[selectedNews.newsVersions.length - 1]
    setNews(lastNews)
    setNewsLikes(selectedNews.newsLikes)
  }, [selectedNews])

  return (
    <React.Fragment>
      <div className={cssClass(style.container, style.container_secondary)}>
        <div className={cssClass(style.project__articles, style.articles)}>
          <div className={cssClass(style.articles__item, style.article)}>
            <div className={cssClass(style.article__header)}>
              <div className={cssClass(style.article__tag, style.tag)}>
                <span className={cssClass(style.tag__text)}>
                  {t('TokenUpdate')} # {index}
                </span>
                {isAuthor && (
                  <button className={cssClass(style.edit__btn)} onClick={isEditNews}>
                    <TokenIcons name={'editIco'} />
                  </button>
                )}
              </div>
              <div className={cssClass(style.article__date, style.date)}>
                <span className={cssClass(style.date__text)}>{FormatedDate(news?.dateCreated)}</span>
              </div>
            </div>
            <div className={cssClass(style.article__main)}>
              <div className={cssClass(style.wysiwyg)}>
                <h2>{news?.title || ''}</h2>
                <h3>{news?.preview || ''}</h3>
                {selectedNewsImage && <img src={selectedNewsImage} alt='image' />}
                <QuillHTMLOutput html={news?.body || ''} />
              </div>
            </div>
            <div className={cssClass(style.article__footer)}>
              <div className={cssClass(style.article__data, style.data)}>
                <ul className={cssClass(style.data__list)}>
                  <li
                    className={cssClass(style.data__item)}
                    onClick={() => {
                      onLikeUpdateForNews(news?.newsItemId)
                    }}
                  >
                    <TokenIcons name={'favorite'} />
                    <span className={cssClass(style.data__text)}>{LikesCount(newsLikes)}</span>
                  </li>
                  <li className={cssClass(style.data__item)}>
                    <TokenIcons name={'comment'} />
                    <span className={cssClass(style.data__text)}>{newsArticleCommentsCount || ''}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {news && <CommentsTab t={t} news={news} onUpdateNewsCommentsCount={onUpdateNewsCommentsCount} />}
        </div>
      </div>
    </React.Fragment>
  )
}

export default NewsArticlesMoreDetail
