import React, { useEffect, useState } from 'react'
import Main from 'pages/common/components/Main/Main'
import { cssClass } from 'helpers/helper'
import style from './style/TokenPage.module.scss'
import header from './img/header.jpg'
import description from './img/description.jpg'
import { Tabs } from './TokenData/TokenData'
import BasicInformationTab from './BasicInformation/BasicInformationTab'
import NewsArticlesTab from './NewsArticles/NewsArticlesTab'
import CommentsTab from './Comments/CommentsTab'
import { useTranslation } from 'react-i18next'
import TokenHeader from './TokenHeader'
import TokenFooter from './TokenFooter'
import api from 'api'
import { useParams } from 'react-router-dom'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'

export default function TokenPage() {
  const { t } = useTranslation()
  const { code } = useParams()
  const history = useHistory()

  const [selectTab, setSelectTab] = useState(Tabs.BasicInformation)
  const [darkmode, setDarkmode] = useState(false)
  const [currencyListing, setCurrencyListing] = useState(null)
  const [exchangeInstruments, setExchangeInstruments] = useState([])
  const [logoImage, setLogoImage] = useState([])
  const [projectImage, setProjectImage] = useState([])
  const [headerImage, setHeaderImage] = useState([])
  const [isAuthor, setIsAuthor] = useState(false)
  const [isUpdateData, setIsUpdateData] = useState(false)
  const [isUpdateCurrencyLstingCommentsCount, setIsUpdateCurrencyLstingCommentsCount] = useState(false)

  const goToEditPage = () => {
    history.push(`/${i18n.language}/tokens/edit/${currencyListing?.currencyCode}`)
  }
  const goToTokenTradePage = () => {
    history.push(`/${i18n.language}/exchange?symbol=${currencyListing?.currencyCode}_USDT`)
  }
  const fetchCurrencyListingImage = (id, setImage) => {
    api
      .fetchCurrencyListingImage(id, { responseType: 'blob' })
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob)
        setImage(objectURL)
      })
      .catch((error) => {
        console.error('Error fetchCurrencyListingImage', error)
      })
  }

  const fetchCurrencyAuthorship = async (ignore) => {
    if (!currencyListing?.currencyCode) return
    try {
      const data = await api.fetchCurrencyAuthorship(currencyListing.currencyCode)
      if (ignore) return
      setIsAuthor(data)
    } catch (error) {
      if (ignore) return
      console.error('Error fetchCurrencyAuthorship', error)
    }
  }
  const fetchCurrencyListing = async () => {
    try {
      const data = await api.fetchCurrencyListing(code)
      setCurrencyListing(data)
    } catch (error) {
      console.error('Error loading currency listing', error)
    }
  }

  const fetchExchangeInstruments = async () => {
    try {
      const data = await api.fetchInstruments()
      const currencies = data.filter(
        (c) => c.currencyPair.currencyFrom.code === code || c.currencyPair.currencyTo.code === code,
      )
      setExchangeInstruments(currencies)
    } catch (error) {
      console.error('Error loading exchange instruments', error)
    }
  }

  const handleUpdateCurrencyListingCommentsCount = () => {
    setIsUpdateCurrencyLstingCommentsCount(true)
  }

  useEffect(() => {
    Promise.all([fetchCurrencyListing(), fetchExchangeInstruments()])
  }, [])

  useEffect(() => {
    if (!isUpdateData) return
    Promise.all([fetchCurrencyListing(), fetchExchangeInstruments()])
    setIsUpdateData(false)
  }, [isUpdateData])

  useEffect(() => {
    let ignore = false
    fetchCurrencyAuthorship(ignore)

    return () => {
      ignore = true
    }
  }, [currencyListing])

  useEffect(() => {
    if (currencyListing?.headerImageId) {
      const imageId = currencyListing.headerImageId
      fetchCurrencyListingImage(imageId, setHeaderImage)
    }
    if (currencyListing?.logoImageId) {
      const imageId = currencyListing.logoImageId
      fetchCurrencyListingImage(imageId, setLogoImage)
    }
    if (currencyListing?.projectImageId) {
      const imageId = currencyListing.projectImageId
      fetchCurrencyListingImage(imageId, setProjectImage)
    }
  }, [currencyListing])

  return (
    <Main>
      <div className={cssClass(style.project, darkmode ? cssClass(style.project_dark) : null)}>
        {selectTab !== Tabs.CreateOrEditNewsArticle && (
          <React.Fragment>
            <picture className={cssClass(style.project__picture)}>
              {headerImage && headerImage.length !== 0 ? (
                <img src={headerImage} />
              ) : (
                <img className={cssClass(style.project__img)} src={header} />
              )}
            </picture>
            <header className={cssClass(style.project__header)}>
              <TokenHeader
                t={t}
                selectTab={selectTab}
                setSelectTab={setSelectTab}
                currencyListing={currencyListing}
                exchangeInstruments={exchangeInstruments}
                projectImage={projectImage}
                onGoToEditPage={goToEditPage}
                onGoToTokenTradePage={goToTokenTradePage}
                isAuthor={isAuthor}
                isUpdateCurrencyLstingCommentsCount={isUpdateCurrencyLstingCommentsCount}
                setIsUpdateCurrencyLstingCommentsCount={setIsUpdateCurrencyLstingCommentsCount}
              />
            </header>
          </React.Fragment>
        )}
        <main className={cssClass(style.project__main)}>
          <div className={cssClass(style.project__tabs, style.tabs)}>
            <div className={cssClass(style.tabs__main)}>
              <div className={cssClass(style.tabs__list)}>
                {selectTab === Tabs.BasicInformation && (
                  <BasicInformationTab
                    t={t}
                    currencyListing={currencyListing}
                    exchangeInstruments={exchangeInstruments}
                    logoImage={logoImage}
                    photoImage={projectImage}
                  />
                )}
                {(selectTab === Tabs.NewsArticles || selectTab === Tabs.CreateOrEditNewsArticle) && (
                  <NewsArticlesTab
                    t={t}
                    code={code}
                    isAuthor={isAuthor}
                    onNewsArticle={(newsArticle) => processNewsArticle(newsArticle)}
                    selectTab={selectTab}
                    setSelectTab={setSelectTab}
                  />
                )}
                {selectTab === Tabs.Comments && (
                  <div className={cssClass(style.tabs__item)}>
                    <div className={cssClass(style.container, style.container_secondary)}>
                      <CommentsTab
                        t={t}
                        currencyListing={currencyListing}
                        onUpdateCurrencyListingCommentsCount={handleUpdateCurrencyListingCommentsCount}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        {selectTab !== Tabs.CreateOrEditNewsArticle && (
          <footer className={cssClass(style.project__footer)}>
            <TokenFooter
              t={t}
              onGoToTokenTradePage={goToTokenTradePage}
              onClickTokenComments={() => setSelectTab(Tabs.Comments)}
            />
          </footer>
        )}
      </div>
    </Main>
  )
}
