import React, { useEffect, useState } from 'react'
import { cssClass } from 'helpers/helper'
import style from '../style/TokenPage.module.scss'
import CommentComponent from './CommentComponent '
import api from 'api'
import { LikesCount } from '../TokenData/TokenData'
import description from '../img/description.jpg'
import { useAuth } from 'auth/auth'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'

export default function CommentsTab({
  t,
  news,
  currencyListing,
  onUpdateNewsCommentsCount,
  onUpdateCurrencyListingCommentsCount,
}) {
  let auth = useAuth()
  const history = useHistory()

  const [commentText, setCommentText] = useState('')
  const [userName, setUserName] = useState(null)
  const [comments, setComments] = useState(null)
  const [sortedComments, setSortedComments] = useState(null)
  const [localDisabledState, setLocalDisabledState] = useState(false)
  const [sortingTypes, setSortingTypes] = useState([
    'TokenSortingTypeNewest',
    'TokenSortingTypeOldest',
    'TokenSortingTypeMostPopular',
  ])
  const [selectedSortingTypes, setSelectedSortingTypes] = useState('TokenSortingTypeNewest')

  const updateLikeForComment = (commentId) => {
    if (!auth.isSignedIn) {
      goToRegistrationPage()
      return
    }

    if (localDisabledState || !commentId) return null
    setLocalDisabledState(true)

    api
      .updateLikeForCurrencyListingNewsComment(commentId)
      .then((data) => {
        setSortedComments((prevSortedComments) =>
          prevSortedComments.map((comment) => {
            if (comment?.id === commentId) {
              return { ...comment, commentLikes: data }
            }

            return comment
          }),
        )
      })
      .catch((error) => {
        console.error(`Error updateLikeForComment`, error)
      })
      .finally(() => {
        setLocalDisabledState(false)
      })
  }

  const sortComments = (comments) => {
    if (!comments || comments.length === 0) return
    let sortedComments
    if (selectedSortingTypes === 'TokenSortingTypeNewest') {
      sortedComments = comments.slice().sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
    } else if (selectedSortingTypes === 'TokenSortingTypeOldest') {
      sortedComments = comments.slice().sort((a, b) => new Date(a.dateCreated) - new Date(b.dateCreated))
    } else if (selectedSortingTypes === 'TokenSortingTypeMostPopular') {
      sortedComments = comments.slice().sort((a, b) => LikesCount(b?.commentLikes) - LikesCount(a?.commentLikes))
    }
    setSortedComments(sortedComments)
  }

  const createNewCommentHandler = () => {
    if (commentText.trim() === '') return
    if (!auth.isSignedIn) goToRegistrationPage()

    const commentDto = {
      newsItemId: news?.newsItemId ?? null,
      currencyListingCode: currencyListing?.currencyCode ?? null,
      userId: '',
      text: commentText,
    }
    if (news && !currencyListing) {
      api
        .createCurrencyListingNewsComment(commentDto)
        .then((newComment) => {
          setComments((prevComments) => [...prevComments, newComment])
          setCommentText('')
          onUpdateNewsCommentsCount(news?.newsItemId)
        })
        .catch((error) => {
          console.error('Error createCurrencyListingNewsComment', error)
        })
    } else if (!news && currencyListing) {
      api
        .createCurrencyListingComment(commentDto)
        .then((newComment) => {
          setComments((prevComments) => [...prevComments, newComment])
          setCommentText('')
          onUpdateCurrencyListingCommentsCount(currencyListing?.currencyCode)
        })
        .catch((error) => {
          console.error('Error createCurrencyListingComment', error)
        })
    }
  }

  const fetchCurrencyListingNewsComment = () => {
    api
      .fetchCurrencyListingNewsComment(news.newsItemId)
      .then((data) => {
        setComments(data)
      })
      .catch((error) => {
        console.error('Error fetchCurrencyListingNews', error)
      })
  }

  const fetchCurrencyListingComments = () => {
    api
      .fetchCurrencyListingComments(currencyListing.currencyCode)
      .then((data) => {
        setComments(data)
      })
      .catch((error) => {
        console.error('Error fetchCurrencyListingNews', error)
      })
  }

  const goToRegistrationPage = () => {
    history.push(`/${i18n.language}/register`)
  }

  useEffect(() => {
    let ignore = false

    if (ignore || !news?.id) return

    fetchCurrencyListingNewsComment()
    return () => {
      ignore = true
    }
  }, [news])

  useEffect(() => {
    let ignore = false
    if (ignore || !currencyListing?.currencyCode) return

    fetchCurrencyListingComments()
    return () => {
      ignore = true
    }
  }, [currencyListing?.currencyCode])

  useEffect(() => {
    if (!auth.isSignedIn) return
    api
      .fetchUserNickname()
      .then((data) => {
        setUserName(data)
      })
      .catch((error) => {
        console.error('Error fetchUserName', error)
      })
  }, [])

  useEffect(() => {
    if (!comments) return
    sortComments(comments)
  }, [comments, selectedSortingTypes])

  return (
    <div className={cssClass(style.project__comments, style.comments)}>
      <div className={cssClass(style.comments__footer)}>
        <div className={cssClass(style.comments__addComment, style.addComment)}>
          <div className={cssClass(style.addComment__header)}>
            <span className={cssClass(style.comments__title)}>
              {news ? t('TokenCommentUpdate') : t('TokenCommentProject')}
            </span>
            <span className={cssClass(style.comments__subtitle)}>
              {t('TokenCommentPlural', { count: sortedComments?.length || '0' })}
            </span>
            <select
              className={cssClass(style.comments__select)}
              onChange={(data) => setSelectedSortingTypes(data.target.value)}
            >
              {sortingTypes &&
                sortingTypes.map((type) => (
                  <option key={type} value={type}>
                    {t(type)}
                  </option>
                ))}
            </select>
          </div>
          <div className={cssClass(style.addComment__main)}>
            <div className={cssClass(style.comment__media)}>
              <div className={cssClass(style.comment__avatar)}>
                <span className={cssClass(style.information__avatar)}>{userName?.charAt(0) || 'U'}</span>
              </div>
            </div>
            <textarea
              className={cssClass(style.comments__text)}
              type='text'
              placeholder={t('TokenWriteComment') + '...'}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            ></textarea>
          </div>
          <div className={cssClass(style.addComment__footer)}>
            <div className={cssClass(style.addComment__action, style.action)}>
              <ul className={cssClass(style.action__list)}>
                <li className={cssClass(style.action__item)}>
                  <button
                    type='button'
                    className={cssClass(style.btn, style.btn_tiny, style.btn_tertiary)}
                    onClick={() => setCommentText('')}
                  >
                    <span className={cssClass(style.btn__text)}>{t('CancelVerb')}</span>
                  </button>
                </li>
                <li className={cssClass(style.action__item)}>
                  <button
                    type='button'
                    className={cssClass(style.btn, style.btn_tiny)}
                    onClick={() => createNewCommentHandler()}
                    disabled={commentText.trim() === ''}
                  >
                    <span className={cssClass(style.btn__text)}>{t('TokenLeaveComment')}</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {sortedComments &&
        sortedComments.map((comment) => (
          <CommentComponent t={t} onLikeOpdateForComment={updateLikeForComment} key={comment.id} comment={comment} />
        ))}
    </div>
  )
}
