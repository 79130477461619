import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import '../../../secondaryLayout/scss/main.sass'
import TokenInput from '../../TokenData/TokenInput.jsx'
import TokenIcons from '../../TokenData/TokenIcons.js'
import { CompressImage, ImageType } from 'pages/tokens/TokenData/TokenData'
import api from 'api'
import defaultHeaderImage from '../../img/header.jpg'
import defaultLogo from '../../img/defaultLogo.png'

export default function TokenImages({
  isLoadExistingImages,
  onImageChange,
  selectedImages,
  setSelectedImages,
  tokenData,
}) {
  const { t } = useTranslation()

  const [isErrorLogoImageSize, setIsErrorLogoImageSize] = useState(false)

  const fetchCurrencyListingImage = (id, imageName) => {
    api
      .fetchCurrencyListingImage(id, { responseType: 'blob' })
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob)

        const fileName = `${imageName}_image.${blob.type.split('/')[1]}`
        const file = new File([blob], fileName, { type: blob.type })

        setSelectedImages((prevImages) =>
          prevImages.map((img) =>
            img.name === imageName ? { ...img, url: objectURL, file: file, isLoadingImage: false } : img,
          ),
        )
      })
      .catch((error) => {
        console.error('Error fetchCurrencyListingImage', error)
      })
  }

  const handleSelectedImages = async (event, imageType) => {
    try {
      let origFile = event.target.files[0]
      if (!origFile || !imageType) return

      let compressFile = origFile
      if (imageType === ImageType.Logo) {
        const isSquare = await checkImageIsSquare(origFile)
        if (!isSquare) {
          setIsErrorLogoImageSize(true)
          return
        }
        setIsErrorLogoImageSize(false)
        compressFile = await CompressImage(origFile)
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedImages((prevData) =>
          prevData.map((item) =>
            item.name === imageType ? { ...item, url: reader.result, file: compressFile } : item,
          ),
        )
        onImageChange(true)
      }
      reader.readAsDataURL(origFile)
    } catch (error) {
      console.error('Error processing image:', error)
      setIsErrorLogoImageSize(true)
    }
  }

  const checkImageIsSquare = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve(img.width === img.height)
      }
      img.onerror = reject
      img.src = URL.createObjectURL(file)
    })
  }

  useEffect(() => {
    if (!isLoadExistingImages) return

    if (tokenData?.headerImageId) {
      const imageId = tokenData.headerImageId
      fetchCurrencyListingImage(imageId, ImageType.Header)
    }
    if (tokenData?.logoImageId) {
      const imageId = tokenData.logoImageId
      fetchCurrencyListingImage(imageId, ImageType.Logo)
    }
    if (tokenData?.projectImageId) {
      const imageId = tokenData.projectImageId
      fetchCurrencyListingImage(imageId, ImageType.Project)
    }
  }, [isLoadExistingImages])

  useEffect(() => {
    if (!selectedImages) return
    setSelectedImages(selectedImages)
  }, [selectedImages])

  const renderImages = (imageType, defImg) => {
    const image = selectedImages.find((img) => img.name === imageType)
    if (!image) return null
    if (image.isLoadingImage) return <TokenIcons name={'blurredTokenIco'} />

    if (!image.url) {
      if (imageType === ImageType.Project) {
        return (
          <React.Fragment>
            <TokenIcons name={'editIco'} />
            <span className='avatar__text'>
              {t('TokenPasteYour')}
              <br />
              {t('TokenProjectImageHere')}
            </span>
          </React.Fragment>
        )
      }
      return <img src={defImg} alt={`${imageType} image`} />
    }

    return <img className='token__img' src={image.url} alt={`${imageType} image`} />
  }

  return (
    <React.Fragment>
      <div id='tokens__images' className='tokens__group group'>
        <div className='group__header'>
          <span className='group__title'>{t('TokenTokenImages')}:</span>
        </div>
        <div className='group__main'>
          <div className='group__header'>
            <div className='token__header'>
              <div className='token__avatar avatar'>
                <input
                  className='avatar__input'
                  type='file'
                  onChange={(event) => handleSelectedImages(event, ImageType.Header)}
                  accept='image/*'
                />
                {renderImages(ImageType.Header, defaultHeaderImage)}
              </div>
              <div className='token__title'>
                <span className='token__text h3'>{t('TokenHeaderImage')}</span>
                <div className='form__footer'>
                  <span className='form__tooltip'> {t('TokenUploadARectangularImage')}</span>{' '}
                  {!tokenData.headerImageId && (
                    <span
                      className='form__tooltip'
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                      onClick={() =>
                        setSelectedImages((prevImages) =>
                          prevImages.map((img) =>
                            img.name === ImageType.Header ? { ...img, url: null, file: null } : img,
                          ),
                        )
                      }
                    >
                      {t('TokenOrUseTheDefault')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='group__header'>
            <div className='token__header'>
              <div className='token__avatar avatar'>
                <input
                  className='avatar__input'
                  type='file'
                  onChange={(event) => handleSelectedImages(event, ImageType.Logo)}
                  accept='image/*'
                />
                {renderImages(ImageType.Logo, defaultLogo)}
              </div>
              <div className='token__title'>
                <span className='token__text h3'>{t('TokenTokenLogo')}</span>
                <div className='form__footer'>
                  <span className={`${isErrorLogoImageSize ? 'form__tooltip_error' : 'form__tooltip'}`}>
                    {isErrorLogoImageSize ? t('TokenTheImageMustBeSquare') : t('TokenUploadASquareImage')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='group__header'>
            <div className='token__header'>
              <div className='token__avatar avatar'>
                <input
                  className='avatar__input'
                  type='file'
                  onChange={(event) => handleSelectedImages(event, ImageType.Project)}
                  accept='image/*'
                />
                {renderImages(ImageType.Project, null)}
              </div>
              <div className='token__title'>
                <span className='token__text h3'>{t('TokenProjectImage')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
